





















































import Draggable from 'vuedraggable';
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';

import ConfirmationDialog from '@/components/confirmation-dialog.vue';

import TableHeader from '@/components/gc/configuration/table-header.vue';

import GCCollectionModel from '@/models/gc/configuration/collection.model';
import GCClassificationModel from '@/models/gc/configuration/classification.model';

import { GCCollectionTypeEnum } from '@/enums/gc/configuration/collection-type.enum';
import { GCActualCollectionEnum } from '@/enums/gc/configuration/actual-collection.enum';
import { GCJustificationTypeEnum } from '@/enums/gc/configuration/justification-type.enum';
import GCGeneralConfigModel from '@/models/gc/configuration.model';

import { ICustomEvent } from '@/interfaces/draggable/custom-event.interface';

@Component({ components: { ConfirmationDialog, TableHeader, Draggable } })
export default class GCTableBody extends Vue {
  @PropSync('computedListOfClassification', { type: Array })
  listOfClassification!: Array<GCClassificationModel>;

  @Prop()
  listOfCollection!: Array<GCCollectionModel>;

  @Prop()
  gcGeneralConfig!: GCGeneralConfigModel;

  listOfJustificationOption = [
    GCJustificationTypeEnum.DontUse,
    GCJustificationTypeEnum.NoSale,
    GCJustificationTypeEnum.BuyUnderRange,
  ];

  listOfCollectionType = [
    GCCollectionTypeEnum.CantBuy,
    GCCollectionTypeEnum.MustBuy,
    GCCollectionTypeEnum.Indifferent,
    GCCollectionTypeEnum.OnlyInSet,
  ];

  moveEvent(event: ICustomEvent): void {
    const { oldIndex } = event;
    const { newIndex } = event;
    const element = this.listOfClassification[oldIndex];

    this.listOfClassification.splice(oldIndex, 1);
    this.listOfClassification.splice(newIndex, 0, element);
  }

  get collectionDescription(): Map<string, string> {
    const description = new Map<string, string>();

    this.listOfClassification.forEach((classification) => {
      classification.listOfCollection.forEach((collection) => {
        let collectionName = collection.collectionNumber.toString();

        if (collection.collectionNumber === GCActualCollectionEnum.Actual) {
          collectionName = 'atual';
        }

        let finalText = collection.collectionType.toString();

        if (GCCollectionTypeEnum.Indifferent === collection.collectionType) {
          finalText = ` pode ou não comprar, na coleção ${collectionName}.`;
        } else if (GCCollectionTypeEnum.OnlyInSet === collection.collectionType) {
          finalText = ' deve comprar em ao menos uma das coleções configuradas como conjunto.';
        } else {
          finalText = ` ${collection.collectionType.toLowerCase()} na coleção ${collectionName}.`;
        }

        description[
          `${classification.id}_${collection.id}`
        ] = `Para o cliente ser ${classification.description} ele ${finalText}`;
      });
    });

    return description;
  }
}
