
















































































































import { AxiosError } from 'axios';
import { inject } from 'inversify-props';
import { Vue, Component, ProvideReactive } from 'vue-property-decorator';

import ConfirmationDialog from '@/components/confirmation-dialog.vue';

import GCTableBody from '@/components/gc/configuration/table-body.vue';
import GCTableHeader from '@/components/gc/configuration/table-header.vue';

import NotificationModel from '@/models/notification.model';
import CollectionGCModel from '@/models/gc/configuration/collection.model';
import ClassificationGCModel from '@/models/gc/configuration/classification.model';

import GCCollectionService from '@/services/gc/configuration/collection.service';
import GCClassificationService from '@/services/gc/configuration/classification.service';

import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { NotificationTypeEnum } from '@/enums/notification-type.enum';
import { GCCollectionTypeEnum } from '@/enums/gc/configuration/collection-type.enum';
import { GCActualCollectionEnum } from '@/enums/gc/configuration/actual-collection.enum';
import { GCJustificationTypeEnum } from '@/enums/gc/configuration/justification-type.enum';
import { GCClassificationUniqueTypeEnum } from '@/enums/gc/configuration/classification-unique-type.enum';
import GCGeneralConfigModel from '@/models/gc/configuration.model';
import GCGeneralConfigService from '@/services/gc/configuration.service';
import SessionService from '@/services/session.service';

@Component({ components: { ConfirmationDialog, GCTableHeader, GCTableBody } })
export default class GCTableConfiguration extends Vue {
  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @inject(InjectionIdEnum.GCClassificationService)
  gcClassificationService!: GCClassificationService;

  @inject(InjectionIdEnum.GCCollectionService)
  gcCollectionService!: GCCollectionService;

  @inject(InjectionIdEnum.GCGeneralConfigService)
  gcGeneralConfigService!: GCGeneralConfigService;

  @ProvideReactive()
  listOfClassification: Array<ClassificationGCModel> = [];

  @ProvideReactive()
  listOfCollection: Array<CollectionGCModel> = [];

  @ProvideReactive()
  gcGeneralConfig: GCGeneralConfigModel = new GCGeneralConfigModel(false);

  showDialog = false;

  msgDialog = '';

  dialogFunction = -1;

  actualCollectinoNumber = GCActualCollectionEnum.Actual;

  addClassification(): void {
    const classificationWithNoName = '';
    const isNotUnique = false;
    this.listOfClassification.push(
      new ClassificationGCModel(
        GCClassificationService.getNextId(this.listOfClassification),
        classificationWithNoName,
        GCJustificationTypeEnum.DontUse,
        GCCollectionService.generateNewListOfCollection(this.listOfCollection),
        isNotUnique,
        GCClassificationUniqueTypeEnum.NotUnique,
      ),
    );
  }

  removeClassification(classiticationToRemove: ClassificationGCModel): void {
    const indexToDelete = this.listOfClassification.indexOf(classiticationToRemove);
    this.listOfClassification.splice(indexToDelete, 1);
  }

  addCollection(): void {
    const nextCollectionNumber = GCCollectionService.getNextCollectionNumber(this.listOfCollection);
    const constCollectionType = GCCollectionTypeEnum.CantBuy;

    const newCollection = new CollectionGCModel(
      GCCollectionService.getNextId(this.listOfCollection),
      nextCollectionNumber,
      constCollectionType,
      nextCollectionNumber,
    );

    this.listOfCollection.push(newCollection);

    this.listOfClassification.forEach((classification) => {
      let collectionType = GCCollectionTypeEnum.CantBuy;

      if (classification.isUnique) {
        collectionType = GCCollectionTypeEnum.Indifferent;
      }

      const newClassificationCollection = new CollectionGCModel(
        GCCollectionService.getNextId(classification.listOfCollection),
        newCollection.collectionNumber,
        collectionType,
        newCollection.collectionNumber,
      );
      classification.listOfCollection.push(newClassificationCollection);
    });
  }

  removeCollection(collectionToDelete: CollectionGCModel): void {
    const indexToDelete = this.listOfCollection.indexOf(collectionToDelete);

    this.listOfCollection.splice(indexToDelete, 1);
    this.listOfClassification.forEach((classification) => {
      classification.listOfCollection.splice(indexToDelete, 1);
    });

    this.refreshListOfCollection();
  }

  refreshListOfCollection(): void {
    this.listOfCollection = this.listOfCollection.map((collection, index) => ({
      ...collection,
      collectionNumber: index,
    }));

    this.listOfClassification.forEach((classification) => {
      const cla = { ...classification };
      cla.listOfCollection = classification.listOfCollection.map((collection, index) => ({
        ...collection,
        collectionNumber: index,
      }));
      Object.assign(classification, cla);
    });
  }

  showConfirmDialog(msg: string, dialogFunction = -1): void {
    this.msgDialog = msg;
    this.msgDialog = msg;
    this.showDialog = true;
    this.dialogFunction = dialogFunction;
  }

  async dialogChoice(acceptChoice: boolean): Promise<void> {
    if (acceptChoice) {
      if (this.dialogFunction === 1) {
        await this.saveChanges();
      }

      if (this.dialogFunction === 2) {
        await this.undoChanges();
      }

      if (this.dialogFunction === 3) {
        await this.reLoadDefaultTheme();
      }
    }
  }

  async reLoadDefaultTheme(): Promise<void> {
    const loader = this.$loading.show();
    try {
      await this.gcClassificationService.reLoadDefaultTheme();
      await this.loadDataFromServer();
      this.$notify.show(new NotificationModel('Recarregado layout padrão com sucesso!', NotificationTypeEnum.Success));
    } catch (error) {
      if (error instanceof Error) {
        this.$notify.show(new NotificationModel(error && error.message));
      }
    } finally {
      loader.hide();
    }
  }

  async undoChanges(): Promise<void> {
    const loader = this.$loading.show();
    try {
      await this.loadDataFromServer();
      this.$notify.show(new NotificationModel('Alterações desfeitas com sucesso!', NotificationTypeEnum.Success));
    } catch (error) {
      if (error instanceof Error) {
        this.$notify.show(new NotificationModel(error && error.message));
      }
    } finally {
      loader.hide();
    }
  }

  async saveChanges(): Promise<void> {
    const loader = this.$loading.show();
    try {
      await this.gcClassificationService.saveClassification(this.listOfClassification);
      await this.loadDataFromServer();
      this.$notify.show(new NotificationModel('Salvo com sucesso!', NotificationTypeEnum.Success));
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError) {
        const msgError = axiosError.response?.data;
        this.$notify.show(new NotificationModel(`Não foi possivel salvar! ${msgError}`));
      }
    } finally {
      loader.hide();
    }
  }

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    try {
      await this.loadDataFromServer();
    } catch (error) {
      if (error instanceof Error) {
        this.$notify.show(new NotificationModel(error && error.message));
      }
    } finally {
      loader.hide();
    }
  }

  async loadDataFromServer(): Promise<void> {
    this.listOfClassification = await this.gcClassificationService.loadClassification();
    this.listOfCollection = await this.gcCollectionService.loadCollections();
    this.gcGeneralConfig = await this.gcGeneralConfigService.loadConfig();
  }

  get canEdit(): boolean {
    return this.sessionService.isAdmOrRoot;
  }
}
