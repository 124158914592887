






import { Vue, Component } from 'vue-property-decorator';
import GCTableConfiguration from '@/components/gc/configuration/table.vue';

@Component({
  components: {
    GCTableConfiguration,
  },
})
export default class GCTeamplate extends Vue {}
