var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('GCTableHeader',{model:{value:(_vm.listOfCollection),callback:function ($$v) {_vm.listOfCollection=$$v},expression:"listOfCollection"}}),_c('GCTableBody',{attrs:{"gcGeneralConfig":_vm.gcGeneralConfig,"listOfCollection":_vm.listOfCollection,"computedListOfClassification":_vm.listOfClassification},on:{"removeClassification":_vm.removeClassification},scopedSlots:_vm._u([{key:"removeClassification",fn:function(ref){
var classificationToRemove = ref.classificationToRemove;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeClassification(classificationToRemove)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}])}),_c('tbody',[_c('tr',[_c('td'),_c('td'),_c('td'),_vm._l((_vm.listOfCollection),function(collection){return _c('td',{key:collection.id,staticClass:"text-center"},[(collection.collectionNumber !== _vm.actualCollectinoNumber)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeCollection(collection)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e()],1)})],2)])]},proxy:true}])})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","width":"10px","height":"85%","disabled":_vm.canEdit == false},on:{"click":function($event){return _vm.addCollection()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-box")])],1)]}}])},[_vm._v(" Adicionar Coleção ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","width":"100%","disabled":_vm.canEdit == false},on:{"click":function($event){return _vm.addClassification()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-box")])],1)]}}])},[_vm._v(" Adicionar Classificação ")])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"ma-3",attrs:{"color":"primary","disabled":_vm.canEdit == false},on:{"click":function($event){return _vm.showConfirmDialog('Salvar todos os dados?', 1)}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-content-save")]),_c('span',[_vm._v("Salvar Alterações")])],1),_c('v-btn',{staticClass:"ma-3",attrs:{"color":"primary","disabled":_vm.canEdit == false},on:{"click":function($event){return _vm.showConfirmDialog('Desfazer todos os dados não salvos?', 2)}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-undo")]),_c('span',[_vm._v("Desfazer Alterações")])],1),_c('v-btn',{staticClass:"ma-3",attrs:{"color":"primary","disabled":_vm.canEdit == false},on:{"click":function($event){return _vm.showConfirmDialog('Restaurar todas as classificações para as padrões?', 3)}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-refresh")]),_c('span',[_vm._v("Recarregar Layout Padrão")])],1)],1),_c('v-row',[_c('ConfirmationDialog',{attrs:{"msg":_vm.msgDialog,"header":"Aviso!"},on:{"choice":_vm.dialogChoice},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }