


















import { Vue, Component, VModel } from 'vue-property-decorator';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import CollectionGCModel from '@/models/gc/configuration/collection.model';
import { GCActualCollectionEnum } from '@/enums/gc/configuration/actual-collection.enum';

@Component({ components: { ConfirmationDialog } })
export default class GCTableHeader extends Vue {
  @VModel()
  listOfCollection!: Array<CollectionGCModel>;

  actualCollectionNumber = GCActualCollectionEnum.Actual;

  get lastCollection(): number {
    let result = 0;

    this.listOfCollection.forEach((collection) => {
      if (result < collection.collectionNumber) {
        result = collection.collectionNumber;
      }
    });

    return result;
  }
}
